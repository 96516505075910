import { Route, Routes, Navigate } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./page/Client/home";
import InfoClient from "./page/Client/Checkout";
import Panier from "./page/Client/Panier";
import ImageUpload from "./page/Client/UploadImagePage";
import { CssBaseline } from "@mui/material";
import Login from "./page/Admin/auth/login";
import { useSelector } from "react-redux";
import PageCommand from "./page/Admin/PageCommadDashboard";
import PageImprimant from "./page/Admin/PageImprimant";
import PageParams from "./page/Admin/PageParams";
import PageSearchCommand from "./page/Admin/PageSearchCommand";
import PageCommandTraiteParCode from "./page/Admin/PageCommandTraiteParcode";
import PageImageToday from "./page/Admin/PageImageToday";
import PageCommandClient from "./page/Admin/CommandClientPage";

export const priviteRoute = [
  { path: "/admin", element: <PageCommand /> },
  { path: "/admin/command", element: <PageCommand /> },
  { path: "/admin/imprimant", element: <PageImprimant /> },
  { path: "/admin/parametre", element: <PageParams /> },
  { path: "/admin/searchCommand", element: <PageSearchCommand /> },
  { path: "/admin/imageTraite/:id", element: <PageCommandTraiteParCode /> },
  { path: "/admin/ImageTodayPage/", element: <PageImageToday /> },
  { path: "/admin/CommandClientPage/:id", element: <PageCommandClient /> },
];

function IndexRoute() {
  const { IsAuth } = useSelector((state) => state.Auth);
  return (
    <div>
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/produit/:type" element={<ImageUpload />} />
          <Route path="/panier" element={<Panier />} />
          <Route path="/commande/:type" element={<InfoClient />} />
          <Route
            path="/login"
            element={IsAuth ? <Navigate to="/admin" /> : <Login />}
          />
          {priviteRoute.map((p) => (
            <Route
              path={p.path}
              element={
                IsAuth ? (
                  p.element
                ) : (
                  <Navigate state={{ form: p.path }} to="/login" />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default IndexRoute;
