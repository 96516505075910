import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Card,
  CardMedia,
  Typography,
  DialogActions,
  Button,
  Checkbox,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  EditeImage,
  GetImageParCommand,
} from "../../store/slice/CommandDahbordSlice";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CropIcon from "@mui/icons-material/Crop";
import axios from "axios";
import ImprimantSucces from "../../composant/message/imprimanteSucces";
import { Api_Token } from "../../env";
import { BackLoadingSimple } from "../loading/backLoading";
import EditImagePopup from "../EditImage";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";

export default function GalleryImageTraite({
  open,
  onClose,
  downloadImage,
  id,
}) {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const imprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant
  );
  const [AllImages, setAllImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openCropper, setOpenCropper] = useState(false);
  const [demonsion, setDemonsion] = useState({});
  const [src, setSrc] = useState();
  const [idImage, setIdImage] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAllImage(images);
  }, [images]);

  function editeImage(Crop, displayCrop, brightness, rotation) {
    setOpenCropper(false);
    const data = {
      ...Crop,
      displayCrop,
      brightness,
      rotation,
      id: idImage,
    };
    dispatch(EditeImage(data)).then((result) => {
      if (EditeImage.fulfilled.match(result)) {
        dispatch(GetImageParCommand(id));
      }
    });
  }

  function EditCountImage(index, number) {
    setAllImage((prev) => {
      const updatedImages = [...prev];
      updatedImages[index] = {
        ...updatedImages[index],
        Number: updatedImages[index].Number + number,
      };
      return updatedImages;
    });
  }

  const handleSelectImage = (id) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((imgId) => imgId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedImages.length === AllImages.length) {
      setSelectedImages([]); // Deselect all
    } else {
      setSelectedImages(AllImages.map((image) => image.id)); // Select all
    }
  };

  const sendToPrint = async () => {
    setLoading(true);
    const selectedImageUrls = AllImages.filter((image) =>
      selectedImages.includes(image.id)
    ).map((image) => ({
      url: image.ImageTraites[0].url,
      type: image.Produit?.NomProduit,
      Number: image.Number,
    }));
    const data = {
      imprimant: imprimant?.nomImprimant,
      image_urls: selectedImageUrls,
    };

    try {
      const response = await axios.post(
        "http://127.0.0.1:5000/print_images",
        data
      );
      if (response.status === 200 || response.status === 207) {
        const results = response.data.results;

        const allPrintedSuccessfully = results.every(
          (result) => result.message === "Image printed successfully"
        );

        console.log("number image :", response.data.total_printed);
        const totalDelay = response.data.total_printed * 50 * 1000; // Convert to milliseconds
        console.log("totalDelay :", totalDelay);
        if (allPrintedSuccessfully) {
          // Show popup after the total delay
          setTimeout(() => {
            setOpenPopup(true);
            setLoading(false);
            // Optionally navigate to another page
            // navigate("/admin/sercheCommand");
          }, totalDelay);
        } else {
          setLoading(false);
          console.error("Some images failed to print");
        }
        Api_Token.post(`command/editPriceCommand`, {
          idCommand: id,
          numberImageImprime: response.data.total_printed,
        });
      } else {
        console.error("Failed to send images. Status code:", response.status);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  console.log(AllImages);

  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogTitle>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Images Traitées
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {AllImages?.map((image, index) => (
              <Grid item xs={3} key={image.id}>
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Checkbox
                        checked={selectedImages.includes(image.id)}
                        onChange={() => handleSelectImage(image.id)}
                        sx={
                          {
                            //  transform: 'scale(2)',
                            //  margin: '10px',
                          }
                        }
                      />
                    </Box>
                    <Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <IconButton
                          onClick={() => {
                            EditCountImage(index, -1);
                          }}
                          //  disabled={c <= 1}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography variant="body1" mx={2}>
                          {image.Number}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            EditCountImage(index, +1);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>

                  <CardMedia
                    onClick={() => handleSelectImage(image.id)}
                    component="img"
                    image={`${
                      image.ImageTraites[0].url
                    }?v=${new Date().getTime()}`}
                  />
                  <IconButton
                    onClick={() => {
                      setIdImage(image.id);
                      setSrc(image.url);
                      setDemonsion(
                        imprimant?.demontions?.find(
                          (d) => image.idProduit === d.idProduit
                        )
                      );
                      setOpenCropper(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      downloadImage(image.ImageTraites[0].url, image.id);
                    }}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            <Button variant="contained" onClick={handleSelectAll}>
              {selectedImages?.length === AllImages?.length
                ? "Désélectionner Tout"
                : "Tout Sélectionner"}
            </Button>
          }
          <Button variant="contained" onClick={sendToPrint}>
            Imprimer Images
          </Button>
          <Button onClick={onClose} variant="contained" color="error">
            Annulé
          </Button>
        </DialogActions>
      </Dialog>
      <ImageCropDialog
        //    open={openCropper}
        onClose={() => {
          setOpenCropper(false);
        }}
        src={src}
        demonsion={demonsion}
        editeImage={editeImage}
      />
      <EditImagePopup
        open={openCropper}
        onClose={() => {
          setOpenCropper(false);
        }}
        src={src}
        demonsion={demonsion}
        onSubmit={editeImage}
      />
      <ImprimantSucces open={openPopup} onClose={() => setOpenPopup(false)} />
      <BackLoadingSimple open={loading} />
    </>
  );
}

export function ImageCropDialog({ open, onClose, src, demonsion, editeImage }) {
  const [crop, setCrop] = useState({
    unit: "%",
    height: 100,
    aspect: demonsion?.typeCroperW / demonsion?.typeCroperH,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <div style={{ width: "50%", margin: "auto" }}>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            aspect={demonsion?.typeCroperW / demonsion?.typeCroperH}
            //  disabled={false}
          >
            <img
              src={src}
              alt="Crop me"
              style={{ maxWidth: "auto", height: "400px" }}
            />
          </ReactCrop>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={() => editeImage(crop)}>Recadrer</Button>
      </DialogActions>
    </Dialog>
  );
}
