import {
  Grid,

  Dialog,


  DialogTitle,
  Typography,
  DialogContent,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ImageCard from "./ImageGallrey";
import { useDispatch } from "react-redux";
import { DeleteImageParTypeAndsave } from "../../store/slice/gestionImageSlice";
import { useState } from "react";
import ConfirmationDelete from "../message/ConfirmationDelete";
import CancelIcon from "@mui/icons-material/Cancel";
function ImageGallreyPanier({ open, images, handleClose, type }) {
  const [deletePopup, setdeletePopup] = useState(false);
  const [dataDelete, setdataDelete] = useState();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "30px",
        },
      }}
      fullWidth={true}
      maxWidth="md" // Vous pouvez ajuster la taille selon vos besoins
    >
      <DialogTitle>
        <Typography
          sx={{
            fontSize: {
              xs: "24px",
              md: "36px",
              lg: "48px",
              xl: "60px",
            },
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          image {type}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ padding: 20 }}>
          <Grid container spacing={2}>
            {images?.map((image, index) => (
              <Grid item xs={12} sm={6} md={2} key={index}>
                <ImageCard
                  src={URL.createObjectURL(image.file)}
                  onDelete={() => {
                    setdataDelete({ type: type, index: index });
                    setdeletePopup(true);
                    
                  }}
                  type={type}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <ConfirmationDelete
        open={deletePopup}
        onClose={() => {
          setdeletePopup(false);
        }}
        onConfirme={() => {
          dispatch(DeleteImageParTypeAndsave(dataDelete));
          setdeletePopup(false);
        }}
      />
    </Dialog>
  );
}

export default ImageGallreyPanier;
