import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Api_Token } from "../../env";

export default function CommandClientPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [commands, setCommand] = useState([]);
  useEffect(() => {
    function GetDataCommand() {
      Api_Token.get(`command/getCommandsByPhone/${id}`)
        .then((response) => {
          setCommand(response.data);
        })
        .catch(() => {});
    }
    GetDataCommand();
  }, [id]);
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Liste des Commandes Clients</h1>{" "}
      <Paper sx={{ width: "90%", margin: "auto", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">Téléphone</TableCell>
                <TableCell align="center">État traitement</TableCell>
                <TableCell align="center">Prix (Dhs)</TableCell>
                <TableCell align="center">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commands.map((row, index) => (
                <TableRow key={index} hover>
                  <TableCell align="center">{row.NomClient}</TableCell>
                  <TableCell align="center">{row.Telephone}</TableCell>
                  <TableCell align="center">{row.etat}</TableCell>
                  <TableCell align="center">{row.prix} Dhs</TableCell>
                  <TableCell align="center">
                    {new Date(row.createdAt).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        position="fixed"
        bottom={0}
        width="100%"
        bgcolor="background.paper"
        p={2}
        boxShadow={3}
        display="flex"
        justifyContent="Right"
        gap={2}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            navigate(`/admin/searchCommand`);
          }}
        >
          finish
        </Button>
      </Box>
    </>
  );
}
