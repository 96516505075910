import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Typography,
  Badge,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CollectionsIcon from "@mui/icons-material/Collections";

const ImageCard = ({ src, onDelete, type, badgeContent = 1 }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card
        style={{
          position: "relative",
          borderRadius: "20px",
          width: "80%",
          textAlign: "center",
        }}
      >
        <Badge
          badgeContent={badgeContent}
          color="error"
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: 2,
          }}
        >
          <CollectionsIcon />
        </Badge>

        <CardMedia
          component="img"
          style={{ width: "100%" }}
          image={src}
          alt="Image"
          sx={{
            width:
              type === "polaroid" ? "100%" : type === "instax" ? "90%" : "80%",
            height: "auto",
            maxWidth:
              type === "polaroid"
                ? "300px"
                : type === "instax"
                ? "400px"
                : "none",
            maxHeight: type === "polaroid" ? "300px" : "none",
            aspectRatio:
              type === "polaroid"
                ? "1 / 1"
                : type === "instax"
                ? "3 / 4"
                : "unset",
          }}
        />

        <IconButton
          onClick={onDelete}
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            color: "#B31312",
          }}
        >
          <CancelIcon />
        </IconButton>
      </Card>
    </Box>
  );
};

export default ImageCard;
