import React, { useEffect, useRef, useCallback } from "react";
import KeyboardComposant from "react-simple-keyboard";
import "simple-keyboard/build/css/index.css";

const CustomKeyboard = ({
  inputValue = "",
  showEmojiOnly = false,
  onChange,
  onKeyPress,

  layout = showEmojiOnly
    ? { default: ["😊 😂 ❤️ 👍 🔥 🎉 🙌"] }
    : {
        default: ["1 2 3", "4 5 6", "7 8 9", "{space} 0 {delete}"],
        //    shift: ["1 2 3 4 5 6 7 8 9 0", "{shift} {space} {delete}"],
        numbers: [
          "1 2 3 4 5 6 7 8 9 0",
          "! @ # $ % ^ & * ( )",
          "{default} {backspace} {delete}",
        ],
        symbols: [
          "~ ` | \\ { } [ ]",
          "< > _ + - = : ;",
          "{default} {space} {delete}",
        ],
      },
  layoutName = "default",
  closeKeyboard,
}) => {
  const wrapperRef = useRef(null);
  const keyboardInstanceRef = useRef(null);

  // Close keyboard if clicking outside
  const handleClickOutside = useCallback(
    (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeKeyboard();
      }
    },
    [closeKeyboard]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (keyboardInstanceRef.current) {
      keyboardInstanceRef.current.setInput(inputValue); // Sync input value
    }
  }, [inputValue]);

  const handleKeyPress = (button) => {
    if (onKeyPress) {
      onKeyPress(button);
    }
  };

  return (
    <div
      ref={wrapperRef} // Wrapper ref for detecting outside clicks
    >
      <KeyboardComposant
        keyboardRef={(r) => (keyboardInstanceRef.current = r)} // Ref for the keyboard instance
        onChange={onChange}
        onKeyPress={handleKeyPress}
        layout={layout}
        layoutName={layoutName}
      />
    </div>
  );
};

export default CustomKeyboard;
