import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  IconButton,
  Button,
  Checkbox,
  Pagination,
} from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import DownloadIcon from "@mui/icons-material/Download";

import { useDispatch, useSelector } from "react-redux";
import {
  EditeImage,
  GetImageParCommand,
} from "../../store/slice/CommandDahbordSlice";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { ImageCropDialog } from "../../composant/admin/galleryImageTraite";
import ImprimantSucces from "../../composant/message/imprimanteSucces";
import { Api_Token } from "../../env";
import { BackLoadingSimple } from "../../composant/loading/backLoading";

export default function PageImageToday() {
  const dispatch = useDispatch();
  const [imprimant, setImprimant] = useState();
  const { id } = useParams();
  const [allImages, setAllImages] = useState([]);
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const [nomImprimant, setnomImprimant] = useState();
  const [openCropper, setOpenCropper] = useState(false);
  const [dimension, setDimension] = useState({});
  const [src, setSrc] = useState();
  const [idImage, setIdImage] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // For server-side pagination
  const navigate = useNavigate();
  const itemsPerPage = 8;

  useEffect(() => {
    fetchImages(currentPage);
  }, [currentPage]);

  const fetchImages = async (page) => {
    try {
      const response = await Api_Token.get(`command/GetImagesToday`, {
        params: { page },
      });
      const data = response.data;
      setAllImages(data?.data ?? []);
      setImprimant(data.imprimant);
      setnomImprimant(data.imprimant?.nomImprimant);
      setTotalPages(data?.totalPages || 1);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function editeImage(crop) {
    setOpenCropper(false);
    const data = { ...crop, id: idImage };
    dispatch(EditeImage(data)).then((result) => {
      if (EditeImage.fulfilled.match(result)) {
        fetchImages(currentPage);
      }
    });
  }

  const handleSelectAll = () => {
    if (selectedImages.length === allImages.length) {
      setSelectedImages([]);
    } else {
      setSelectedImages(allImages.map((img) => img.id));
    }
  };

  const handleSelectImage = (id) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((imgId) => imgId !== id) : [...prev, id]
    );
  };

  const handleDownloadImage = async (url, id) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(response.data);
      link.href = objectURL;
      link.download = `image${id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const send = async () => {
    setLoading(true);
    const selectedImageUrls = allImages
      .filter((image) => selectedImages.includes(image.id))
      .map((image) => ({
        url: image.ImageTraites[0].url,
        type: image.Produit.NomProduit,
      }));
    const data = {
      imprimant: nomImprimant,
      image_urls: selectedImageUrls,
    };

    try {
      const response = await axios.post(
        "http://127.0.0.1:5000/print_images",
        data
      );
      if (response.status === 200 || response.status === 207) {
        const results = response.data;
        const numberImageImprime = results.filter(
          (result) => result.message === "Image printed successfully"
        ).length;
        const allPrintedSuccessfully = results.every(
          (result) => result.message === "Image printed successfully"
        );

        if (allPrintedSuccessfully) {
          setOpenPopup(true);
        } else {
          alert("Some images failed to print:", results);
        }
        Api_Token.post(`command/editPriceCommand`, {
          idCommand: id,
          numberImageImprime: numberImageImprime,
        });
      } else {
        console.error("Failed to send images. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  console.log(dimension);
  return (
    <Box>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Traitement des images
      </Typography>

      <Grid container sx={{ marginBottom: "100px" }} spacing={2}>
        {allImages?.map((image) => (
          <Grid item xs={3} key={image.id}>
            <Card>
              <Checkbox
                checked={selectedImages.includes(image.id)}
                onChange={() => handleSelectImage(image.id)}
                sx={{
                  transform: "scale(2)",
                  margin: "10px",
                }}
              />
              <CardMedia
                onClick={() => handleSelectImage(image.id)}
                component="img"
                image={`${
                  image.ImageTraites[0]?.url
                }?v=${new Date().getTime()}`}
              />
              <Box display="flex" justifyContent="space-between" p={1}>
                <IconButton
                  onClick={() => {
                    setIdImage(image.id);
                    setSrc(image.url);
                    console.log(image.idProduit);
                    setDimension(
                      imprimant?.demontions?.find(
                        (d) => image.idProduit === d.idProduit
                      )
                    );
                    setOpenCropper(true);
                  }}
                >
                  <CropIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    handleDownloadImage(image.ImageTraites[0]?.url, image.id)
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages} // Total number of pages
          page={currentPage} // Current active page
          onChange={handlePageChange} // Handle page changes
          color="primary"
        />
      </Box>
      <Box
        position="fixed"
        bottom={0}
        width="100%"
        bgcolor="background.paper"
        p={2}
        boxShadow={3}
        display="flex"
        justifyContent="Right"
        gap={2}
      >
        <Pagination
          count={totalPages} // Total number of pages
          page={currentPage} // Current active page
          onChange={handlePageChange} // Handle page changes
          color="primary"
        />
        <Button variant="contained" onClick={handleSelectAll}>
          {selectedImages.length === allImages.length
            ? "Désélectionner tout"
            : "Sélectionner tout"}
        </Button>
        <Button variant="contained" onClick={send}>
          photos today
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            navigate("/admin");
          }}
        >
          Close
        </Button>
      </Box>
      <ImageCropDialog
        open={openCropper}
        onClose={() => {
          setOpenCropper(false);
        }}
        src={src}
        demonsion={dimension}
        editeImage={editeImage}
      />
      <ImprimantSucces
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          navigate("/admin/searchCommand");
        }}
      />
      <BackLoadingSimple open={loading} />
    </Box>
  );
}
