import React from "react";
import { Backdrop, CircularProgress, Box, Typography } from "@mui/material";

// Component for displaying a circular progress bar with a label (percentage)
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        style={{
          width: "80px", // Customize width
          height: "80px", // Customize height
        }}
        {...props}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          color="textPrimary" // Changed from "white" to better match Material-UI theme
          sx={{
            fontSize: "1.5rem", // Custom font size
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

// Backdrop component with progress and percentage label
export default function BackLoading({ open, Progress }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed", // Ensure fixed positioning
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999, // Use Material-UI theme zIndex for layering
      }}
      open={open}
    >
      <CircularProgressWithLabel value={Progress} />
    </Backdrop>
  );
}

// Simple loading backdrop with a spinner
export function BackLoadingSimple({ open }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed", // Ensure fixed positioning
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999, // Use Material-UI theme zIndex for layering
      }}
      open={open}
    >
      <CircularProgress />
    </Backdrop>
  );
}
