import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import EditIcon from "@mui/icons-material/Edit";

import { useDispatch, useSelector } from "react-redux";
import {
  EditeImage,
  GetImageParCommand,
} from "../../store/slice/CommandDahbordSlice";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { ImageCropDialog } from "../../composant/admin/galleryImageTraite";
import ImprimantSucces from "../../composant/message/imprimanteSucces";
import { Api_Token } from "../../env";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import EditImagePopup from "../../composant/EditImage";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function PageCommandTraiteParcode() {
  const dispatch = useDispatch();
  const imprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant
  );
  const { id } = useParams();
  const [allImages, setAllImages] = useState([]);
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const nomImprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant?.nomImprimant
  );
  const [openCropper, setOpenCropper] = useState(false);
  const [dimension, setDimension] = useState({});
  const [src, setSrc] = useState();
  const [idImage, setIdImage] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAllImages(images ?? []);
  }, [images]);

  useEffect(() => {
    dispatch(GetImageParCommand(id));
  }, [dispatch, id]);

  function editeImage(Crop, displayCrop, brightness, rotation) {
    setOpenCropper(false);
    const data = {
      ...Crop,
      displayCrop,
      brightness,
      rotation,
      id: idImage,
    };
    dispatch(EditeImage(data)).then((result) => {
      if (EditeImage.fulfilled.match(result)) {
        dispatch(GetImageParCommand(id));
      }
    });
  }
  function EditCountImage(index, number) {
    setAllImages((prev) => {
      const updatedImages = [...prev];
      updatedImages[index] = {
        ...updatedImages[index],
        Number: updatedImages[index].Number + number,
      };
      return updatedImages;
    });
  }

  const handleSelectAll = () => {
    if (selectedImages.length === allImages.length) {
      setSelectedImages([]);
    } else {
      setSelectedImages(allImages.map((img) => img.id));
    }
  };

  const handleSelectImage = (id) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((imgId) => imgId !== id) : [...prev, id]
    );
  };

  const send = async () => {
    setLoading(true);

    const selectedImageUrls = allImages
      .filter((image) => selectedImages.includes(image.id))
      .map((image) => ({
        url: image.ImageTraites[0].url,
        type: image.Produit?.NomProduit,
        Number: image.Number,
      }));

    const data = {
      imprimant: nomImprimant,
      image_urls: selectedImageUrls,
    };

    try {
      const response = await axios.post(
        "http://127.0.0.1:5000/print_images",
        data
      );

      if (response.status === 200 || response.status === 207) {
        const results = response.data.results;

        const allPrintedSuccessfully = results.every(
          (result) => result.message === "Image printed successfully"
        );

        console.log("number image :", response.data.total_printed);
        const totalDelay = response.data.total_printed * 50 * 1000; // Convert to milliseconds
        console.log("totalDelay :", totalDelay);
        if (allPrintedSuccessfully) {
          // Show popup after the total delay
          setTimeout(() => {
            setOpenPopup(true);
            setLoading(false);
            // Optionally navigate to another page
            // navigate("/admin/sercheCommand");
          }, totalDelay);
        } else {
          setLoading(false);
          console.error("Some images failed to print");
        }
        Api_Token.post(`command/editPriceCommand`, {
          idCommand: id,
          numberImageImprime: response.data.total_printed,
        });
      } else {
        console.error("Failed to send images. Status code:", response.status);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Traitement des images
      </Typography>

      <Grid container sx={{ marginBottom: "100px" }} spacing={2}>
        {allImages?.map((image, index) => (
          <Grid item xs={3} key={image.id}>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Checkbox
                    checked={selectedImages.includes(image.id)}
                    onChange={() => handleSelectImage(image.id)}
                    sx={
                      {
                        //  transform: 'scale(2)',
                        //  margin: '10px',
                      }
                    }
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <IconButton
                      onClick={() => {
                        EditCountImage(index, -1);
                      }}
                      //  disabled={c <= 1}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="body1" mx={2}>
                      {image.Number}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        EditCountImage(index, +1);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <CardMedia
                onClick={() => handleSelectImage(image.id)}
                component="img"
                image={`${image.ImageTraites[0].url}?v=${new Date().getTime()}`}
              />
              <IconButton
                onClick={() => {
                  setIdImage(image.id);
                  setSrc(image.url);
                  setDimension(
                    imprimant?.demontions?.find(
                      (d) => image.idProduit === d.idProduit
                    )
                  );
                  setOpenCropper(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        position="fixed"
        bottom={0}
        width="100%"
        bgcolor="background.paper"
        p={2}
        boxShadow={3}
        display="flex"
        justifyContent="Right"
        gap={2}
      >
        <Button variant="contained" onClick={handleSelectAll}>
          {selectedImages.length === allImages.length
            ? "Désélectionner tout"
            : "Sélectionner tout"}
        </Button>
        <Button variant="contained" onClick={send}>
          Imprimer Images
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            navigate(`/admin/CommandClientPage/${id}`);
          }}
        >
          Terminer
        </Button>
      </Box>
      <EditImagePopup
        open={openCropper}
        onClose={() => {
          setOpenCropper(false);
        }}
        src={src}
        demonsion={dimension}
        onSubmit={editeImage}
      />
      <ImprimantSucces
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
      />
      <BackLoadingSimple open={loading} />
    </Box>
  );
}
